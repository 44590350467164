import React from "react";

function spieleig() {
  return (
    <div className="container p-sm-4">
      <h3>TEILNAHMEBEDINGUNGEN</h3>
      <h2>INSTAGRAM-GEWINNSPIEL</h2>
      <ol>
        <li>
          Das Gewinnspiel steht in keiner Verbindung zu Instagram und wird in keiner Weise von Instagram gesponsert,
          unterstützt oder organisiert. Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist nicht
          Instagram, sondern Punscheninwien. Die bereitgestellten Informationen werden einzig für das Gewinnspiel
          verwendet
        </li>
        <li>
          Eine Teilnahme am Gewinnspiel ist ausschließlich zu den hier aufgeführten Bedingungen möglich. Mit der
          Teilnahme erkennt der Teilnehmer diese Teilnahmebedingungen ausdrücklich an. Der Veranstalter behält sich vor,
          das Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen abzubrechen oder zu
          beenden.
        </li>
        <li>
          Die Anmeldung zum Gewinnspiel erfolgt über einen Kommentar bei unserem Instagram-Posting zu diesem
          Gewinnspiel. Mehrere Kommentare werden als eine einzelne Anmeldung gewertet.
        </li>
        <li>
          Kommentare, die gegen die Instagram-Richtlinien, österreichisches Recht, unsere Netiquette und/oder das
          Copyright verstoßen, werden nach Kenntnisnahme ohne Ankündigung entfernt. Der Teilnehmer ist damit von der
          Verlosung ausgeschlossen.
        </li>
        <li>
          Teilnahmeberechtigt sind Personen mit Wohnsitz in Österreich, die bei der Teilnahme mindestens 18 Jahre alt
          und bei Instagram „Follower“ von Punscheninwien sind. Nicht teilnahmeberechtigt sind Mitarbeiter,
          Bevollmächtigte, Rechtsnachfolger und Beauftragte von Punscheninwien sowie deren Angehörige.
        </li>
        <li>
          Die Verlosung des Gewinns erfolgt nach Ablauf des Durchführungszeitraumes unter Ausschluss der Öffentlichkeit
          und Gewährleistung des Zufallsprinzips.
        </li>
        <li>
          Der Gewinner wird über die Instagram-Kommentarfunktion des Instagram-Postings auf Facebook informiert. Der
          Gewinner muss innerhalb von 5 Werktagen (ab Gewinnbenachrichtigung) seine Kontakt- und Adressdaten per „Direct
          message“ an die Punscheninwien Instagram-Seite melden. Sollte der Gewinner die Annahme seines Gewinnes in
          dieser Zeit nicht bestätigen, verfällt sein Anspruch auf den Gewinn ersatzlos. Eine Verpflichtung zur Annahme
          des Gewinnes besteht nicht.
        </li>
        <li>
          Soweit im Rahmen des Gewinnspiels personenbezogene Daten von Teilnehmern erfasst werden, werden diese vom
          Veranstalter ausschließlich zum Zwecke der Durchführung des Gewinnspiels erhoben, verarbeitet und genutzt
          sowie nach Beendigung des Gewinnspiels gelöscht. Der Teilnehmer erklärt sich damit ausdrücklich einverstanden.
          Der Teilnehmer kann diese Zustimmung jederzeit durch Brief, Fax oder E-Mail widerrufen. Für die Korrektheit
          und Vollständigkeit seiner abgeschickten Daten ist der Teilnehmer selbst verantwortlich.
        </li>
        <li>Keine Barablöse, der Rechtsweg ist ausgeschlossen.</li>
      </ol>
    </div>
  );
}

export default spieleig;
